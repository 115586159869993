import React, { useCallback, useContext } from "react";
import { useFetch } from "../../hooks/useFetch";
import { Link } from "react-router-dom";
import { ApiContext } from "../../apiURL";

export const List = () => {
  const { data, request } = useFetch();
  const { apiURL } = useContext(ApiContext);
  const deleteItem = useCallback(
    ({ target }) => {
      const confirm = window.confirm(`Delete register ${target.id}`);
      if (confirm) {
        request(`${apiURL}/product/${target.id}`, {
          method: "DELETE",
        });
        request(`${apiURL}/product`);
      }
    },
    [request]
  );

  React.useEffect(() => {
    request(`${apiURL}/product`);
  }, [request]);

  return (
    <div className="container">
      <div className="row my-3">
        <Link to="/create" className="btn btn-primary">
          Criar
        </Link>
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">#id</th>
            <th scope="col">Nome</th>
            <th scope="col">Preço</th>
            <th scope="col">Editar</th>
            <th scope="col">Deletar</th>
          </tr>
        </thead>
        <tbody>
          {!!data &&
            data.map((v, i) => (
              <tr key={i}>
                <th scope="row">{v.id}</th>
                <td>
                  <Link to={"/" + v.id}> {v.name} </Link>
                </td>
                <td>{v.price}</td>
                <td>
                  <a
                    href={"/edit/" + v.id}
                    className="btn btn-outline-dark btn-sm"
                  >
                    Editar
                  </a>
                </td>
                <td>
                  <a
                    href={"#" + v.id}
                    id={v.id}
                    className="btn btn-outline-danger btn-sm"
                    onClick={deleteItem}
                  >
                    Deletar
                  </a>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
