import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { List } from "./pages/list";
import { Create } from "./pages/create";
import { Edit } from "./pages/edit";
import { ListOne } from "./pages/listOne";
import { ApiBrowserContext } from "./apiURL";

// Cria o roteamento em da aplicacao
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <List />,
      },
      {
        path: ":id?",
        element: <ListOne />,
      },
      {
        path: "create",
        element: <Create />,
      },
      {
        path: "edit/:id?",
        element: <Edit />,
      },
    ],
  },
]);

// Pega o elemento root no /public/index.html e cria o virtual dom
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApiBrowserContext>
    <RouterProvider router={router} />
  </ApiBrowserContext>
);
