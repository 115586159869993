import React from "react";

export const ApiContext = React.createContext({});

// apiURL
export const ApiBrowserContext = ({ children }) => {
  return (
    <ApiContext.Provider value={{ apiURL: "https://api.everycodes.com" }}>
      {children}
    </ApiContext.Provider>
  );
};
