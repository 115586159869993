import { Link, Outlet } from "react-router-dom";

function App() {
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-dark text-light shadow">
        <div className="container-fluid">
          <ul className="navbar-nav text-white">
            <li className="nav-item">
              <Link
                to={"/"}
                className="nav-link active text-white"
                aria-current="page"
                href="#"
              >
                Listar
              </Link>
            </li>
            <li className="nav-item ">
              <Link className="nav-link text-white" to="/create">
                Criar
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      <div className="container mt-2">
        <ul class="list-group">
          <li class="list-group-item shadow" aria-current="true">
            <h2 className="text-center">Alunos</h2>
          </li>
          <li class="list-group-item">Wictor Mencalha Dornas</li>
          <li class="list-group-item">Thalita Samara </li>
          <li class="list-group-item">Samira Jeovana</li>
          <li class="list-group-item">Ana do Valle</li>
          <li class="list-group-item">Rafael Carmo</li>
        </ul>
      </div>
      <div className="container mt-5">
        {/*  Permite que as rotas funcionem */}
        <Outlet />
      </div>
    </>
  );
}

export default App;
