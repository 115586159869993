import React, { useContext, useEffect, useState } from "react";
import { useFetch } from "../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { ApiContext } from "../../apiURL";

export const Create = () => {
  const [form, setForm] = useState();
  const navigation = useNavigate();
  const [isDisabled, setIiSdisabled] = useState(true);
  const { request, data } = useFetch();
  const { apiURL } = useContext(ApiContext);

  const handlerForm = React.useCallback(
    ({ target }) => {
      setForm((data) => ({ ...data, [target.name]: target.value }));
      if (form?.name?.length > 1 && form?.price) {
        setIiSdisabled(false);
      } else {
        setIiSdisabled(true);
      }
    },
    [form, setForm]
  );
  const handlerSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      const data = { ...form, price: Number(form?.price) };
      request(`${apiURL}/product`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
    },
    [request, form]
  );

  useEffect(() => {
    console.log(data);
    if (data) {
      navigation("/" + data.id);
    }
  }, [data, navigation]);
  return (
    <form onSubmit={handlerSubmit}>
      <legend>Criar novo produto</legend>
      <div className="mb-3">
        <label htmlFor="disabledTextInput" className="form-label">
          Nome
        </label>
        <input
          onChange={handlerForm}
          type="text"
          id="disabledTextInput"
          className="form-control"
          placeholder="Name"
          name="name"
          value={form?.name || ""}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="price" className="form-label">
          Preço
        </label>
        <input
          onChange={handlerForm}
          type="text"
          className="form-control"
          id="price"
          placeholder="Price"
          name="price"
          value={form?.price || ""}
        />
      </div>
      <button disabled={isDisabled} type="submit" className="btn btn-primary">
        Enviar
      </button>
    </form>
  );
};
