import React, { useCallback, useContext } from "react";
import { useFetch } from "../../hooks/useFetch";
import { Link, useParams } from "react-router-dom";
import { ApiContext } from "../../apiURL";

export const ListOne = () => {
  const { data, request } = useFetch();
  const { id } = useParams();
  const { apiURL } = useContext(ApiContext);
  const deleteItem = useCallback(
    ({ target }) => {
      // Dialog rde comfirmação de excluzão
      const confirm = window.confirm(`Deletar registro ${target.id}`);
      if (confirm) {
        request(`${apiURL}/product/${target.id}`, {
          method: "DELETE",
        });
        request(`${apiURL}/product`);
      }
    },
    [request]
  );

  React.useEffect(() => {
    request(`${apiURL}/product/${id}`);
  }, [request]);

  React.useEffect(() => {}, [data]);

  return (
    <div className="container">
      <div className="row my-3">
        <Link to="/create" className="btn btn-primary">
          Criar
        </Link>
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">#id</th>
            <th scope="col">Nome</th>
            <th scope="col">Preço</th>
            <th scope="col">Editar</th>
            <th scope="col">Deletar</th>
          </tr>
        </thead>
        <tbody>
          {!!data && (
            <tr>
              <th scope="row">{data.id}</th>
              <td>{data.name}</td>
              <td>{data.price}</td>
              <td>
                <a
                  href={"/edit/" + data.id}
                  className="btn btn-outline-dark btn-sm"
                >
                  Editar
                </a>
              </td>
              <td>
                <a
                  href={"#" + data.id}
                  id={data.id}
                  className="btn btn-outline-danger btn-sm"
                  onClick={deleteItem}
                >
                  Deletar
                </a>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
