import React, { useContext, useState } from "react";
import { useFetch } from "../../hooks/useFetch";
import { useNavigate, useParams } from "react-router-dom";
import { ApiContext } from "../../apiURL";

// Criacao do component
export const Edit = () => {
  // varialveis de estatodo Ex: variavel form, setForm é o manipulador do estado para que o virtual DOM saiba o que foi alterado
  const { id } = useParams();
  const { request, data } = useFetch();
  const [form, setForm] = useState();
  const navitation = useNavigate();
  const [updated, setUpdated] = useState(false);
  const { apiURL } = useContext(ApiContext);
  // useCallback é uma função que evita a recriacao de funcao al renderizar um component o que
  const handlerSubmit = React.useCallback(
    (e) => {
      e.preventDefault();

      const data = { ...form, price: Number(form?.price) };
      request(`${apiURL}/product/${id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      setUpdated(true);
    },
    [form]
  );

  const handlerForm = React.useCallback(({ target }) => {
    setForm((data) => ({ ...data, [target.name]: target.value }));
  }, []);

  /*useEffect é uma funcao que possui um array de dependencias ex: [id, requset ] sempre que ouver uma alteração em uma das dependencia
   o component e atualizado caso o [] seja vasia ele so executa na criacao do component
  */
  React.useEffect(() => {
    setForm({ name: "", price: 0 });
    request(`${apiURL}/product/${id || 0}`);
  }, [id, request, setForm]);

  React.useEffect(() => {}, [data]);
  React.useEffect(() => {
    console.log(data);
    console.log(apiURL);
    if (data) {
      setForm(data);
    }
    if (updated && data) {
      navitation("/" + data.id);
    }
  }, [data]);

  // Component Jsx
  return (
    <form onSubmit={handlerSubmit}>
      <legend>Editar produto</legend>
      <div className="mb-3">
        <label htmlFor="disabledTextInput" className="form-label">
          Nome
        </label>
        <input
          type="text"
          id="disabledTextInput"
          className="form-control"
          placeholder="Name"
          name="name"
          value={form?.name || ""}
          onChange={handlerForm}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="price" className="form-label">
          Preço
        </label>
        <input
          type="text"
          className="form-control"
          id="price"
          placeholder="Price"
          name="price"
          value={form?.price || ""}
          onChange={handlerForm}
        />
      </div>
      <button type="submit" className="btn btn-primary">
        Enviar
      </button>
    </form>
  );
};
