import React from "react";

// um custom hooh reponsavel pelas request
export const useFetch = () => {
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [loadind, setLoading] = React.useState(false);
  const request = React.useCallback(async (url, options = {}) => {
    try {
      setLoading(true);
      setError(null);
      const response = await fetch(url, options);
      let json = null;
      if (response.ok) {
        json = await response.json();
      }
      setData(json);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }, []);
  return { data, loadind, error, request };
};
